import Link from "next/link";

export default function Footer3() {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-c038552 elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default"
      data-id="c038552"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-91cae65"
          data-id="91cae65"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-07b5b19 elementor-widget elementor-widget-text-editor"
              data-id="07b5b19"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                © 2023 IS Auditr. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>

        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f64bf94"
          data-id="f64bf94"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-dbebb57 e-grid-align-right e-grid-align-mobile-center elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons"
              data-id="dbebb57"
              data-element_type="widget"
              data-widget_type="social-icons.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-social-icons-wrapper elementor-grid">
                  <span className="elementor-grid-item">
                    <Link
                      rel="noopener"
                      target="_blank"
                      title="Facebook"
                      href="https://www.facebook.com/isauditr"
                      className="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-push elementor-repeater-item-2ffba9f"
                    >
                      <span className="elementor-screen-only">Facebook-f</span>
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                  </span>

                  <span className="elementor-grid-item">
                    <Link
                      rel="noopener"
                      target="_blank"
                      title="Twitter"
                      href="https://twitter.com/isauditr"
                      className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-push elementor-repeater-item-e40c902"
                    >
                      <span className="elementor-screen-only">Twitter</span>
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </span>

                  <span className="elementor-grid-item">
                    <Link
                      rel="noopener"
                      target="_blank"
                      title="Linkedin"
                      href="https://www.linkedin.com/company/isauditr/"
                      className="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-push elementor-repeater-item-ba71140"
                    >
                      <span className="elementor-screen-only">Linkedin-in</span>
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </span>

                  <span className="elementor-grid-item">
                    <Link
                      rel="noopener"
                      target="_blank"
                      title="Instagram"
                      href="https://www.instagram.com/isauditr/"
                      className="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-animation-push elementor-repeater-item-ee5d380"
                    >
                      <span className="elementor-screen-only">Instagram</span>
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </span>

                  <span className="elementor-grid-item">
                    <Link
                      rel="noopener"
                      target="_blank"
                      title="Pinterest"
                      href="https://www.pinterest.com/isauditr/"
                      className="elementor-icon elementor-social-icon elementor-social-icon-pinterest elementor-animation-push elementor-repeater-item-5a9ce43"
                    >
                      <span className="elementor-screen-only">Pinterest</span>
                      <i className="fab fa-pinterest"></i>
                    </Link>
                  </span>

                  <span className="elementor-grid-item">
                    <Link
                      rel="noopener"
                      target="_blank"
                      title="Quora"
                      href="https://isauditr1.quora.com/"
                      className="elementor-icon elementor-social-icon elementor-social-icon-quora elementor-animation-push elementor-repeater-item-5a9ce43"
                    >
                      <span className="elementor-screen-only">Quora</span>
                      <i className="fab fa-quora"></i>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
